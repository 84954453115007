import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import BannerImage from '../../components/BannerImage'

const content = {
  title: {
    pl: 'Zarząd',
    en: 'Management',
  },
  data: [
    {
      name: 'Marek Rostkowski',
      title: 'CEO OMIDA Group',
      desc: {
        pl: 'Od 2002 roku jest związany z branżą TSL. Absolwent studiów MBA Wyższej Szkoły Bankowej w Gdańsku. Współzałożyciel oraz Prezes Omida VLS Sp. z o.o. Motywują go kolejne sukcesy. Uważa, że ważnym elementem rozwoju każdego człowieka jest możliwość samodoskonalenia się, dlatego jest mentorem w Fundacji Columbus oraz programie rozwoju dla młodzieży licealnej The Grade. Stojąc na czele OMIDA Group wspiera aktywności na rzecz rozwoju kompetencji pracowników oraz integracji. Zaangażowany w wiele lokalnych inicjatyw. Kieruje się dewizą, że "dzięki uczeniu się i stosowaniu zdobytej wiedzy możesz rozwiązać każdy problem, pokonać każdą przeszkodę i osiągnąć każdy cel, jaki sobie postawisz."',
        en: 'He has been a part of the TSL industry since 2002. A graduate of MBA studies at the WSB University in Gdańsk. Co-founder and President of Omida VLS Sp. z o.o. He is motivated by the ever continuing success in business. He believes that an important element of every person`s development is self-improvement, which is why is a mentor in the Columbus Foundation and a development program for high school students known as The Grade. As the leader of OMIDA Group, he supports activities aimed at the development of employee competence and integration. He is involved in many local initiatives. He lives by the motto that "by learning and applying your knowledge, you can solve every problem, overcome every obstacle and achieve every goal you set"',
      },
      image: 'image1',
    },
    {
      name: 'Bartłomiej Glinka',
      title: 'CEO OMIDA Group',
      desc: {
        pl: `Z branżą TSL związany od ponad 20 lat. Od 2010 z sukcesami zarządza grupą spółek Omida, tworząc tym samym jedną z najszybciej rozwijających się firm TSL w Polsce.</br></br>Absolwent Wydziału Ekonomicznego Uniwersytetu Gdańskiego. Ukończył studia z tytułem magistra na specjalności Handel Morski. Absolwent studiów MBA Wyższej Szkoły Bankowej w Gdańsku. Członek Rady Ekspertów Uniwersytetu Gdańskiego. Fundator Fundacji Columbus ukierunkowanej na kształcenie liderów w biznesie i doskonalenie  umiejętności młodzieży. Mentor i jeden z założycieli Liceum Columbus.`,
        en: `With the TSL industry for over 20 years. Since 2010, he has been successfully managing the OMIDA Group of companies, thus creating, under the name of the company TSL in Poland, revenue in 2021, awarding PLN 1,100,000,000.</br></br>A graduate of the Faculty of Economics at the University of Gdańsk. He graduated with a master's degree with a specialization in Sea Trade. A graduate of the MBA studies at the WSB University in Gdańsk. Member of the Council of Experts at the University of Gdańsk. Founder of the Columbus Foundation focused on educating business leaders and improving youth skills. Mentor and one of the founders of Columbus High School.`,
      },
      image: 'image2',
    },
  ],
}

const Chiefs = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image1: file(
        absolutePath: { regex: "/images/poznaj-nas/marek-rostkowski.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/poznaj-nas/bartlomiej-glinka.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <>
      <Section top>
        <Headline
          h={2}
          title={content.title[lang]}
          //
          large
          gradient
          //
          // medium
          // uppercase
          // dimmed
          // center
        />
      </Section>
      <Section mobile bottom>
        {content.data.map(({ name, title, desc, image }, id) => (
          <BannerImage
            square
            title={name}
            subtitle={title}
            desc={desc[lang]}
            image={query[image]}
            key={id}
            h={3}
          />
        ))}
      </Section>
    </>
  )
}

export default Chiefs
